import { ModifierPhases, Offsets, State, createPopper } from '@popperjs/core';
import { nothing } from 'lit-html';
import { html, property } from 'lit-element';
import {
  register,
  nextUniqueId,
  event,
  EventEmitter,
  KatLitElement,
} from '../../shared/base';
import {
  isPageDirectionRightToLeft,
  offHtmlDirChange,
  onHtmlDirChange,
} from '../../utils/rtl-utils';
import {
  hasChildren,
  firstNonNull,
  eventOriginatedInside,
} from '../../shared/utils';
import { checkSlots } from '../../shared/slot-utils';
import { ifNotNull } from '../../utils/directives';
import { createAnnouncer } from '../../utils/aria';
import baseStyles from '../../shared/base/base.lit.scss';
import { isMobile, mobileMediaQuery } from '../../utils/mobile-helpers';
import styles from './popover.lit.scss';

/**
 * @component {kat-popover} KatalPopover A popover displays simple or complex content to the user and can be set to appear when the user performs a hover, click, or focus action on text, a link, an icon, or another UI element. Learn more about the positioning nuance in the <a href="/design-system/tutorials/popover/">popover tutorial</a>.
 * @example ClickButtonTrigger {
 * "kat-aria-behavior": "tooltip",
 * "position": "bottom",
 * "trigger-type": "click",
 * "content": "
 *   <kat-button slot=\"trigger\" variant=\"primary\">Click me!</kat-button>
 *   This is an example popover triggered inline.
 * "}
 * @example ClickCustomTrigger {
 * "kat-aria-behavior": "tooltip",
 * "trigger-type": "click",
 * "position": "bottom",
 * "content": "
 *   <button slot=\"trigger\" class=\"kat-no-style\">
 *     Click here to trigger a popover
 *     <kat-icon name=\"arrow_drop_down\" size=\"small\" data-popover-anchor></kat-icon>
 *   </button>
 *   This is an example popover triggered inline.
 * "}
 * @example FocusTrigger {
 * "kat-aria-behavior": "tooltip",
 * "position": "right",
 * "trigger-type": "focus",
 * "content": "
 *   <kat-input slot=\"trigger\" type=\"text\" katal-1-2-spacing-fixes placeholder=\"Focus this to trigger popover\"></kat-input>
 *   This is an example popover triggered inline.
 * "}
 * @example Error {
 * "kat-aria-behavior": "tooltip",
 * "variant": "error",
 * "content": "
 *   <kat-button slot=\"trigger\" variant=\"primary\">Click me!</kat-button>
 *   This is an example popover triggered inline.
 * "}
 * @example Complex {
 * "kat-aria-behavior": "interactive",
 * "position": "right",
 * "content": "
 *   <kat-button slot=\"trigger\">Click me to show popover</kat-button>
 *   This is an example popover with complex content.
 *   <kat-input type=\"text\" label=\"Form input\" constraint-label=\"More text\"></kat-input>
 * "}
 * @example HoverButtonTrigger {
 * "kat-aria-behavior": "tooltip",
 * "position": "right",
 * "trigger-type": "hover",
 * "content": "
 *   <kat-button slot=\"trigger\" variant=\"primary\">Hover me!</kat-button>
 *   This is an example popover triggered inline.
 * "}
 * @subcomponent ./popover-trigger.ts
 * @subcomponent ./popover-hide.ts
 * @guideline Do Popover trigger should only include one interactive element to trigger the popover.
 * @guideline Dont Popovers triggered by focus should not contain any interactive elements.
 * @status Production
 * @slot default The contents will be used as the contents of the popover.
 * @slot trigger Optional, the contents will be used as the element that "triggers" the popover. The popover anchors to this element. It can be anchored to a sub-element of the trigger by adding a `data-popover-anchor` attribute on the desired anchor element.
 * @themes flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-popover')
export class KatPopover extends KatLitElement {
  /**
   * Determines if the popover is currently visible. This can be set to true/false if the popover has a trigger slot.
   * Otherwise, `show` should be called, passing in the element to anchor to as the first argument.
   */
  @property()
  visible?: boolean;

  /** If present, the popover will not render. Child content is still rendered. */
  @property()
  disabled?: boolean;

  /**
   * Determines how the user can hide the popover. Defaults to "click".
   * @enum {value} click Clicks outside the popover or its trigger will cause the popover to hide.
   * @enum {value} manual Popover will only hide when its trigger is clicked or unfocused, or the hide() method is called.
   */
  @property({ attribute: 'hide-behavior' })
  hideBehavior: 'click' | 'manual' = 'click';

  /**
   * Determines the visual style of the popover. At present only affects the background and border color.
   * @enum {value} info Used for a standard neutral informational popover.
   * @enum {value} success Used to represent a successful state.
   * @enum {value} error Used to represent an error or invalid state.
   * @enum {value} alert Used to represent a warning.
   * @enum {value} white Non-semantic style with a white background and blue border.
   * @enum {value} snow Non-semantic style with an off-white background and a grey border.
   * @enum {value} azure Non-semantic style with an off-white background and a blue border.
   * @enum {value} jungle-mist Non-semantic style with a green background and no border.
   * @enum {value} zircon Non-semantic style with a grey background and no border.
   * @enum {value} tooltip Use when the popover is purely informational.
   */
  @property()
  variant = 'info';

  /**
   * Defines how accessibility controls are designed for the popover and associated trigger. Defaults to "interactive".
   * Use "interactive" if your popover has interactive controls. This option will automatically create an invisible close button for screen readers and will
   * associate the popover with triggers that control it. Use "tooltip" if your popover is not interactive, e.g. it is text-only. This option will associate the popover
   * to the trigger and adds settings that should make screen readers announce the contents of the popover when displayed. Use "none" when your use-case for
   * the popover and trigger aren't covered by the other options. If you choose this option you should be careful to implement accessibility controls
   * correctly.
   * @enum {value} interactive
   * @enum {value} tooltip
   * @enum {value} none
   */
  @property({ attribute: 'kat-aria-behavior' })
  katAriaBehavior = 'interactive';

  /**
   * Determines how a user triggers the associated popover to show and hide when interacting with this trigger.
   * Defaults to click.
   * @enum {value} click When clicked and the popover is hidden the popover will be shown and vice-versa.
   * @enum {value} focus When an element inside the trigger is focused the popover appears. If the element is blurred the popover closes.
   * @enum {value} hover Show the popover when an element inside the trigger is hovered. If the trigger is clicked, the popover will remain open until it loses focus. Otherwise, it will close when no longer hovered.
   */
  @property({ attribute: 'trigger-type' })
  triggerType: 'click' | 'focus' | 'hover' = 'click';

  /**
   * Determines which side of the trigger the popover will be anchored to. The popover will
   * try to anchor the side specified, unless there isn't enough room - then it will automatically flip to the other side.
   * Defaults to "bottom".
   * @enum {value} auto Position where there is the most space
   * @enum {value} auto-start Position where there is the most space, towards the start of the trigger
   * @enum {value} auto-end Position where there is the most space, towards the end of the trigger
   * @enum {value} top Position above
   * @enum {value} top-start Position above and to the left
   * @enum {value} top-end Position above and to the right
   * @enum {value} bottom Position below
   * @enum {value} bottom-start Position below and to the left
   * @enum {value} bottom-end Position below and to the right
   * @enum {value} right Position to the right
   * @enum {value} right-start Position to the right, near the top of the trigger
   * @enum {value} right-end Position to the right, near the bottom of the trigger
   * @enum {value} left Position to the left
   * @enum {value} left-start Position to the left, near the top of the trigger
   * @enum {value} left-end Position to the left, near the bottom of the trigger
   */
  @property()
  position = 'bottom';

  /**
   * On small-width screens, a KatPopover will expand to the width of the viewport.
   * Setting this property to true will cause KatPopovers to expand only to the width of their content, as they do for desktop-sized screens.
   *
   * Defaults to false.
   */
  @property({ attribute: 'disable-mobile-full-width' })
  disableMobileFullWidth = false;

  static get styles() {
    return [baseStyles, styles];
  }

  _trigger: HTMLElement;
  _content: HTMLElement;

  _contentId = nextUniqueId();
  _currentAnchor = null;
  _currentTrigger = null;
  _wasOpenOnCapture = false;
  _ariaAnnounce = () => {};
  _popper: {
    hide: () => void;
    show: (anchor: HTMLElement) => void;
    setPlacement: (placement: string) => void;
  };

  constructor() {
    super();
    this._listeners = [
      {
        // We check if the popover was already open between the capture and bubble
        // phase of a particular event.
        target: document,
        listeners: [
          ['click', this._onDocumentClickCapture, true],
          ['click', this._onDocumentClick],
        ],
      },
    ];

    this.observeChildren(mutations => {
      mutations.forEach(mut => {
        mut.removedNodes.forEach(node => {
          if (node.slot === 'trigger') {
            // when a slotted trigger element is removed, clean up the aria
            // attributes that we added after render
            this._removeAriaControls(node);
          }
        });
      });
      this._updateAriaControls();
      this.requestUpdate();
    });
  }

  connectedCallback() {
    super.connectedCallback();
    onHtmlDirChange(() => this._onHtmlDirChange());
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    offHtmlDirChange(() => this._onHtmlDirChange());
  }

  firstUpdated() {
    super.firstUpdated();
    this._ariaAnnounce = createAnnouncer(this._shadow('.announcer'));
    this._content = this._shadow('.content');
    if (isPageDirectionRightToLeft()) {
      this._content.setAttribute('dir', 'rtl');
    }

    this._trigger = this._shadow('.trigger');
    this._popper = popperManager(
      this._content,
      this._shadow('.arrow'),
      (offset: Offsets) => this._modifyPopperOffset(offset)
    );
    mobileMediaQuery.addEventListener('change', this._onMediaQueryListChange);
  }

  updated(changed) {
    super.updated(changed);
    if (changed.has('visible') && this.visible) {
      this.show();
    }

    if (changed.has('katAriaBehavior') || changed.has('visible')) {
      this._updateAriaControls();
    }
  }

  /**
   * Programmatically show the popover. By default, it will anchor to the
   * element inside of the `trigger` slot. The anchor can further be customized
   * by adding a `data-popover-anchor` attribute to an element within the
   * trigger that should be used as the anchor instead.
   *
   * The popover can also be anchored to a completely different element, by
   * passing that element as the first argument to `show`.
   *
   * If no anchor is given, and this popover doesn't have an element in its
   * `trigger` slot, then this is a no-op.
   *
   * @method
   * @param {HTMLElement?} anchor An optional element to anchor to. By default, the element in the `trigger` slot is used.
   */
  async show(anchor?: HTMLElement) {
    if (this.disabled) {
      return;
    }

    if (!(anchor instanceof HTMLElement) && checkSlots(this).trigger) {
      anchor = this._trigger;
    }

    if (!anchor) {
      return;
    }

    this._currentTrigger = anchor;

    const dataAnchor = firstNonNull([
      () => anchor.querySelector<HTMLElement>('[data-popover-anchor]'),
      // the trigger slot is in the light dom of popover, not the
      // trigger, so we have to check in both places
      () =>
        this.querySelector<HTMLElement>(
          '[slot="trigger"] [data-popover-anchor]'
        ),
    ]);

    if (dataAnchor) {
      anchor = dataAnchor;
    }

    if (this.visible && anchor === this._currentAnchor) {
      return;
    }

    const cancelled = !this._show.emit();
    if (cancelled) return;

    this._currentAnchor = anchor;
    this.visible = true;

    await this.updateComplete;

    window.requestAnimationFrame(() => {
      this._popper.show(anchor);
      this._setPopperPlacement();
      anchor.style.animationPlayState = 'running';
      setTimeout(() => {
        anchor.style.animationPlayState = 'paused';
      }, 200);
    });
  }

  /**
   * Programmatically close the popover. Does nothing if the popover is not open.
   * @method
   */
  hide() {
    if (!this.visible) return;

    const cancelled = !this._hide.emit();
    if (cancelled) return;

    this.visible = false;
    this._popper.hide();

    // Note: tooltip behavior does nothing on hide
    if (this.katAriaBehavior === 'interactive') {
      this._ariaAnnounce('Popup closed');

      // Focus the trigger to help the user resume the same flow, but only if
      // focus was already in this popover
      if (
        hasChildren(this._currentTrigger) &&
        this.contains(document.activeElement)
      ) {
        // if this trigger is internal, then we want to focus whatever is in the
        // trigger slot
        if (this._currentTrigger === this._trigger) {
          this._control?.focus();
        } else {
          this._currentTrigger.children[0].focus();
        }
      }
    }

    this._currentAnchor = null;
    this._currentTrigger = null;
  }

  get _control() {
    return this.querySelector<HTMLElement>('[slot="trigger"]');
  }

  _onDocumentClickCapture = () => {
    this._wasOpenOnCapture = this.visible;
  };

  _onDocumentClick = e => {
    if (this.hideBehavior !== 'click') return;

    // In other words we only hide the popover if it was open before this click
    // happened, it's still open, and the click was not on the trigger or
    // popover itself. Without this the popover could close immediately after
    // opening.
    if (
      this._wasOpenOnCapture &&
      this.visible &&
      !eventOriginatedInside(this, e) &&
      !eventOriginatedInside(this._currentTrigger, e)
    ) {
      this.hide();
    }
  };

  private _onHtmlDirChange() {
    if (!this.visible) {
      return;
    }

    this._setPopperPlacement();
  }

  private _onMediaQueryListChange() {
    if (!this.visible) {
      return;
    }

    this._setPopperPlacement();
  }

  private _isPopperMobile() {
    return !this.disableMobileFullWidth && isMobile();
  }

  private _modifyPopperOffset(offset: Offsets) {
    if (!this._isPopperMobile()) {
      return;
    }

    const defaultOffset = parseFloat(
      window.getComputedStyle(document.body).getPropertyValue('--kat-space-05')
    );

    /**
     * Popper.js will set `position: absolute` on the popover element, which positions the element relative to the
     * closest positioned ancestor. If the popover is a child of a positioned element, there may not be enough space
     * to offset by the default --kat-space-05. In this case, fallback to offsetting by half the difference in width
     * between the popover element and its positioned ancestor in order to properly center the popover.
     */

    const fallbackOffset = this.offsetParent
      ? (this.offsetParent.clientWidth -
          this.shadowRoot.querySelector('[part=popover-content]').clientWidth) /
        2
      : Infinity;

    const popperOffset = Math.min(defaultOffset, fallbackOffset);

    offset.x = popperOffset;
  }

  private _setPopperPlacement() {
    let placement = this.position;

    if (this._isPopperMobile()) {
      placement = 'top';
    } else if (isPageDirectionRightToLeft()) {
      const hash = {
        end: 'start',
        start: 'end',
        left: 'right',
        right: 'left',
      };

      placement = placement.replace(
        /start|end|right|left/g,
        matched => hash[matched]
      );
    }

    this._popper.setPlacement(placement);
  }

  /** Fires whenever the popover becomes visible. Calling `preventDefault` on the event will prevent the popover from showing. */
  @event('show', { cancelable: true })
  private _show: EventEmitter;

  /** Fires whenever the popover is hidden. Calling `preventDefault` on the event will prevent the popover from hiding. */
  @event('hide', { cancelable: true })
  private _hide: EventEmitter;

  _removeAriaControls(el) {
    el.removeAttribute('aria-controls');
    el.removeAttribute('aria-expanded');
    el.removeAttribute('aria-describedby');
  }

  _updateAriaControls() {
    const popoverId = this._contentId;
    const control = this._control;

    if (!control) return;

    this._removeAriaControls(control);

    switch (this.katAriaBehavior) {
      case 'interactive':
        // Interactive treats the popover like a panel with interactive elements
        control.setAttribute('aria-controls', popoverId);
        control.setAttribute('aria-expanded', !!this.visible);
        break;
      case 'tooltip':
        // Tooltip treats the popover as a tooltip with simple content
        control.setAttribute('aria-describedby', popoverId);
        break;
    }
  }

  render() {
    let role;
    let tabindex;
    let ariaClose = nothing;

    switch (this.katAriaBehavior) {
      case 'tooltip':
        role = 'tooltip';
        break;
      case 'interactive':
        tabindex = '0';
        ariaClose = html`
          <kat-popover-hide class="aria-close">
            <button
              class="kat-no-style"
              tabindex="-1"
              aria-label="Inside popup, click this to close"
            ></button>
          </kat-popover-hide>
        `;
        break;
    }

    // In the below html, `arrow` (a square div rotated 45deg) gets sandwiched
    // between `content` and `content-wrap`. This is so that it it appears
    // *under* `content-wrap` (hiding the excess parts), but *over* `content`
    // (hiding `content`'s border).

    return html`
      <div class="announcer kat-visually-hidden" aria-live="polite"></div>
      <kat-popover-trigger
        type=${this.triggerType}
        position=${this.position}
        class="trigger"
      >
        <slot name="trigger"></slot>
      </kat-popover-trigger>
      <div
        class="content"
        part="popover-content"
        id=${this._contentId}
        role=${ifNotNull(role)}
        tabindex=${ifNotNull(tabindex)}
      >
        ${ariaClose}
        <div class="content-wrap">
          <slot></slot>
        </div>
        <div class="arrow"></div>
      </div>
    `;
  }
}

function popperManager(content, arrow, modifyPopperOffset) {
  let inst;

  // https://popper.js.org/docs/v2/tutorial/#performance
  const hide = () => {
    inst?.destroy();
    inst = null;
  };

  // Bridges gap between PopperJS modifier, this component's class instance, and what popperManager returns.
  const mobileModifier = {
    name: 'mobileModifier',
    enabled: true,
    phase: 'main' as ModifierPhases,
    requires: ['computeStyles'],
    fn({ state }: { state: State }) {
      modifyPopperOffset(state.modifiersData.popperOffsets);
    },
  };

  const show = el => {
    hide();
    inst = createPopper(el, content, {
      modifiers: [
        {
          name: 'arrow',
          options: { element: arrow, padding: 2 },
          requires: ['mobileModifier'],
        },
        {
          name: 'offset',
          options: {
            offset: () => getPopperOffset(arrow), // See https://popper.js.org/docs/v2/modifiers/offset/
          },
        },
        mobileModifier,
      ],
    });
  };

  const setPlacement = placement => {
    inst?.setOptions({ placement });
  };

  return { show, hide, setPlacement };
}

// Only exported to test
export function getPopperOffset(arrow: HTMLElement) {
  const arrowWidth = arrow.offsetWidth;
  const borderWidth = window
    .getComputedStyle(arrow, '::before')
    .getPropertyValue('border-width');

  if (borderWidth && typeof arrowWidth === 'number') {
    const arrowDiagonal = Math.sqrt(2) * arrow.offsetWidth;
    return [0, Math.round(arrowDiagonal / 2) + parseInt(borderWidth, 10)];
  }

  // Fallback to legacy offset values.
  return [0, 9];
}
