import { Auth } from 'aws-amplify';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { ENV_CONSTANTS } from 'src/utils/AuthProvider';

export async function getUserAliasFromAuth() {
  const currentSession = await Auth.currentSession();
  return currentSession.getIdToken().payload.identities[0].userId;
}

export async function getToken() {
  const currentSession = await Auth.currentSession();
  return currentSession.getIdToken().getJwtToken();
}

export async function getCredentialsFromAWSService() {
  const REGION = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Region;
  let COGNITO_LOGIN: any = {};
  COGNITO_LOGIN[getCognitoDomain()] = await getToken();
  return fromCognitoIdentityPool({
    clientConfig: { region: REGION },
    identityPoolId: 'us-east-1:bf966c89-27c2-4f64-97b3-9b0a00b952ef',
    logins: COGNITO_LOGIN
  });
}

function getCognitoDomain() {
  const REGION = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Region;
  return 'cognito-idp.' + REGION + '.amazonaws.com/' + 'us-east-1_CgS99MSZP';
}
